.brand-name{
    font-size: 16px;
    margin-left: 6px;
    width: 100px;
    //color: get-color(primary, 2);
}
.entity{
    background-color: #ECEDED;
    border-radius: 3px;
    .form-input{
        width: 248px;
        //background-color: #fff;
        display: inline-block;
        padding-right: 10px;
    }
    .subscribe{
        display: inline-block;
        width: 28px;
        .anticon{
            margin-left: 0px;
            text-align: center;
        }
    }
}