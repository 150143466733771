.common-layout {
    background: color-bg(body);
    color: color(com-font);
    h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
        font-family: "Inter", sans-serif;
        font-weight: 800;
        clear: both;
        color: color(com-font);
        word-wrap: break-word;
        overflow-wrap: break-word;
    }
    header {
        background: color(heading);
        .brand-name {
            color: color(heading-inverse2);
        }

        .header-nav-toggle+.header-nav {
            a:not(.button) {
                color: color(heading-inverse2);
            }

            a.button-primary {
                //color: white;
                margin-left: inherit;
                font-weight: lighter;
                color: color(heading-inverse2);
                background: color(heading);
            }
        }
        
       
    }

    .site-content{
        .bread-crumb{
            color: color(heading-inverse2);
            font-size: 18px;
            a{
                color: color(low-contrast);
            }
        }
    }
    .default-form{
        input{
            width: 350px;
        }
    }
    .site-footer {
        position: relative;
        background: color(footer2);
        color: color(footing-inverse);

        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: color-bg(footer2);
            z-index: -3;
        }

        .site-footer-inner {
            padding: $footer--padding__mobile 0;
            border-top: 0px;
            margin-top: 30px;

            .footer-top {
                border: 1px dashed color(mid-contrast);
                padding-left: 2px;

                .brand {
                    .link {
                        color: color(footing-inverse-company);
                    }
                }

                .footer-social {

                    a {

                        svg {
                            fill: color-icon(social2);
                            transition: fill .15s ease;

                            .invert-color & {
                                fill: color-icon(social-inverse);
                            }
                        }

                        &:hover {
                            svg {
                                fill: color-icon(social-hover2);

                                .invert-color & {
                                    fill: color-icon(social-hover-inverse);
                                }
                            }
                        }
                    }
                }
            }

            .footer-bottom {
                .footer-copyright {
                    a:not(.button) {
                        color: color(footing-inverse-company);
                    }
                }

                .footer-beian {
                    font-size: 12px;
                    line-height: 22px;
                    display: inline-flex;
                    a{
                        color: #9CA9B3;
                    }
                    .beian-icon {
                        margin-left: 10px;
                    }
                }
                .footer-nav {
                    a {
                        color: color(footing-inverse-company);
                    }

                }
            }

        }
    }
    .ant-back-top-icon svg{
        color: color(com-font);
    }
}