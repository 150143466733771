// Call to action (core/sections/_contact.scss)
$contact--padding-t__mobile: 20px; // contact, top padding (mobile)
$contact--padding-b__mobile: 20px; // contact, bottom padding (mobile)
$contact--padding-t__desktop: 20px; // contact, top padding (desktop)
$contact--padding-b__desktop: 20px; // contact, bottom padding (desktop)
$contact-slogan--padding-v__mobile: 20px; // contact slogan, margin bottom (mobile)
$contact-slogan--padding-v__desktop: null; // contact slogan, margin bottom (desktop)
$contact-slogan--padding-h__desktop: 48px; // contact slogan, minimum distance between slogan and actions (only .contact-split desktop)
$contact-split-header--padding__mobile: 10px;
$contact-split--padding-t__desktop:20px;
$contact-split--padding-b__desktop:20px;
$contact-split-header--padding__desktop:20px;
$contact-split--padding-b__desktop:10px;