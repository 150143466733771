.contact {

	.section-inner {
		padding-top: $contact--padding-t__mobile;
		padding-bottom: $contact--padding-b__mobile;
        display: contents;
	}
    .section-header {
		padding-bottom: $contact-split-header--padding__mobile;
	}
    .form-input, .form-select, .form-checkbox input, .form-radio input{
        background-color:color(layout);
    }
}

.contact-slogan {
	margin-bottom: $contact-slogan--padding-v__mobile;
}

@include media( '>medium' ) {

	.contact {

		.section-inner {
			padding-top: $contact--padding-t__desktop;
			padding-bottom: $contact--padding-b__desktop;
		}
	}

	.contact-split {
		display: -webkit-flex; //sarfar
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.contact-slogan {
		margin-bottom: $contact-slogan--padding-v__desktop;

		.contact-split & {
			margin-bottom: 0;
			margin-right: $contact-slogan--padding-h__desktop;
		}
	}
}
@include media( '>medium' ) {

	.contact {

		.section-inner {
			padding-top: $contact-split--padding-t__desktop;
			padding-bottom: $contact-split--padding-b__desktop;
		}

		.section-header {
			padding-bottom: $contact-split-header--padding__desktop;
		}
	}
}


