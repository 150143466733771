.ant-message {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: fixed;
  top: 8px;
  left: 0;
  z-index: 1010;
  width: 100%;
  pointer-events: none;

  .ant-message-notice {
    padding: 8px;
    text-align: center;
  }

  .ant-message-notice-content {
    display: inline-block;
    padding: 10px 16px;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
    pointer-events: all;
  }

  .anticon {
    position: relative;
    top: 1px;
    margin-right: 8px;
    font-size: 16px;
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .anticon svg {
    display: inline-block;
  }

  svg:not(:root) {
    overflow: hidden;
  }

  .anticon>* {
    line-height: 1;
  }

  img,
  svg,
  video {
    display: block;
    height: auto;
    max-width: 100%;
  }

  .ant-message-error .anticon {
    color: #ff4d4f;
  }

  .ant-message-success .anticon {
    color: #52c41a;
  }

  .ant-message-info .anticon,
  .ant-message-loading .anticon {
    color: #1890ff;
  }

  .ant-message-warning .anticon {
    color: #faad14;
  }

  .ant-message-info .anticon,
  .ant-message-loading .anticon {
    color: #1890ff;
  }


  .ant-message-notice.ant-move-up-leave.ant-move-up-leave-active {
    animation-name: MessageMoveOut;
    animation-duration: 0.3s;
  }
}

@keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }

  100% {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}

.ant-message-rtl {
  direction: rtl;

  span {
    direction: rtl;
  }

  .anticon {
    margin-right: 0;
    margin-left: 8px;
  }
}

/*
.ant-message {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: fixed;
  top: 8px;
  left: 0;
  z-index: 1010;
  width: 100%;
  pointer-events: none;
}

.ant-message-notice {
  padding: 8px;
  text-align: center;
}
.ant-message-notice-content {
  display: inline-block;
  padding: 10px 16px;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
  pointer-events: all;
}
.ant-message-rtl {
  direction: rtl;
}
.ant-message-rtl span {
  direction: rtl;
}
.ant-message-rtl .anticon {
  margin-right: 0;
  margin-left: 8px;
}
.ant-message .anticon {
  position: relative;
  top: 1px;
  margin-right: 8px;
  font-size: 16px;
}

.ant-message-rtl span {
  direction: rtl;
}
.anticon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.anticon svg {
  display: inline-block;
}
svg:not(:root) {
  overflow: hidden;
}
.anticon > * {
  line-height: 1;
}
img, svg, video {
  display: block;
  height: auto;
  max-width: 100%;
}
*/


