.about{
    .section-inner {
		padding-top: $contact--padding-t__mobile;
		padding-bottom: $contact--padding-b__mobile;
        display: contents;
	}
    .section-header {
		padding-bottom: $contact-split-header--padding__mobile;
	}
    .features-tiles-item-header{
        .img-icon{
            border-radius: 10px;
            display: block;
        }
    }
}
@include media( '>medium' ) {

	.about {

		.section-inner {
			padding-top: $contact--padding-t__desktop;
			padding-bottom: $contact--padding-b__desktop;
		}
	}
}
@include media( '>medium' ) {

	.about {

		.section-inner {
			padding-top: $contact-split--padding-t__desktop;
			padding-bottom: $contact-split--padding-b__desktop;
		}

		.section-header {
			padding-bottom: $contact-split-header--padding__desktop;
		}
	}
}