.ant-back-top {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #000000d9;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    position: fixed;
    right: 100px;
    bottom: 50px;
    z-index: 10;
    width: 40px;
    height: 40px;
    cursor: pointer;

    &:empty {
        display: none;
    }

    &-rtl {
        right: auto;
        left: 100px;
        direction: rtl;
    }

    &-content {
        width: 40px;
        height: 40px;
        overflow: hidden;
        color: #fff;
        text-align: center;
        background-color: fade(#000, 45%);
        border-radius: 20px;
        transition: all 0.3s;

        &:hover {
            background-color: fade(#000, 85%);
            transition: all 0.3s;
        }
    }

    &-icon {
        font-size: 24px;
        line-height: 40px;
    }
}

/*
responsive
*/
@media screen and (max-width: 768px) {
    .ant-back-top {
        right: 60px;
        &-rtl {
            right: auto;
            left: 60px;
        }
    }
}

@media screen and (max-width: 480px) {
    .ant-back-top {
        right: 20px;

        &-rtl {
            right: auto;
            left: 20px;
        }
    }
}